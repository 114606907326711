export const APIRoute = {
    Common: {
        Auth: {
            Validate: {
                url: 'auth/validate-auth/',
                method: 'get',
            },
            Login: {
                url: 'auth/login/',
                method: 'post',
            },
            Logout: {
                url: 'auth/logout/',
                method: 'get',
            },
            Dropout: {
                url: 'auth/dropout/',
                method: 'delete',
            },
            ValidateUsername: {
                url: 'auth/validate-username/',
                method: 'get',
            },
            FindPassword: {
                url: 'auth/find-password/',
                method: 'post',
            },
            ModifyPassword: {
                url: 'users/change-password/',
                method: 'patch',
            },
            FindId: {
                url: 'auth/find-username/',
                method: 'get',
            },
            LoadNice: {
                url: 'auth/load-nice/',
                method: 'get',
            },
            ValidateNice: {
                url: 'auth/validate-nice/',
                method: 'get',
            },
        },
        User: {
            Patch: {
                url: 'users/',
                method: 'patch',
            },
        },
        Concept: {
            List: {
                url: 'concepts/',
                method: 'get',
            },
        },
    },
    ResearchAssistant: {
        Auth: {
            Register: {
                url: 'research-assistant/profiles/',
                method: 'post',
            },
        },
        Profile: {
            Patch: {
                url: 'research-assistant/profiles/',
                method: 'patch',
            },
        },
        Announcement: {
            List: {
                url: 'research-assistant/announcements/',
                method: 'get',
            },
        },
        TestReport: {
            EmailValidation: {
                url: 'research-assistant/profile-verification/',
                method: 'post',
            },
            EmailValidationStatus: {
                url: 'research-assistant/email-code-validation/',
                method: 'get',
            },
            EmailCodeCheck: {
                url: 'research-assistant/email-code-validation/',
                method: 'post',
            },
            ValidationPost: {
                url: 'research-assistant/profiles/',
                method: 'post',
            },
            Create: {
                url: 'research-assistant/test-report/',
                method: 'post',
            },
            Check: {
                url: 'research-assistant/test-report/',
                method: 'get',
            },
        },
        PointsWithdrawal: {
            Create: {
                url: 'research-assistant/points-withdrawals/',
                method: 'post',
            },
        },
        PointLog: {
            List: {
                url: 'research-assistant/point-logs/',
                method: 'get',
            },
        },
        InitialContext: {
            Retrieve: {
                url: 'research-assistant/initial-context/',
                method: 'get',
            },
        },
        Reward: {
            List: {
                url: 'research-assistant/rewards/',
                method: 'get',
            },
        },
        Mission: {
            List: {
                url: 'research-assistant/missions/',
                method: 'get',
            },
            Permitted: {
                url: 'research-assistant/missions/permitted/',
                method: 'get',
            },
        },
        MissionReport: {
            List: {
                url: 'research-assistant/mission-reports/',
                method: 'get',
            },
            Retrieve: {
                url: 'research-assistant/mission-reports/',
                method: 'get',
            },
            Create: {
                url: 'research-assistant/mission-reports/',
                method: 'post',
            },
            Patch: {
                url: 'research-assistant/mission-reports/',
                method: 'patch',
            },
            Submit: {
                url: 'research-assistant/mission-reports/',
                method: 'post',
            },
            Delete: {
                url: 'research-assistant/mission-reports/',
                method: 'delete',
            },
        },
        DongaScience: {
            Create: {
                url: 'research-assistant/donga-sciences/',
                method: 'post',
            },
            List: {
                url: 'research-assistant/donga-reports/',
                method: 'get',
            },
            Retrieve: {
                url: 'research-assistant/donga-reports/',
                method: 'get',
            },
        },
    },
    Admin: {
        Announcement: {
            List: {
                url: 'admin/announcements/',
                method: 'get',
            },
            Create: {
                url: 'admin/announcements/',
                method: 'post',
            },
            Delete: {
                url: 'admin/announcements/',
                method: 'delete',
            },
        },
        SchoolSubject: {
            List: {
                url: 'admin/school-subjects/',
                method: 'get',
            },
            Retrieve: {
                url: 'admin/school-subjects/',
                method: 'get',
            },
            Create: {
                url: 'admin/school-subjects/',
                method: 'post',
            },

            Patch: {
                url: 'admin/school-subjects/',
                method: 'patch',
            },
            Delete: {
                url: 'admin/school-subjects/',
                method: 'delete',
            },
        },
        SchoolSubjectUnit: {
            List: {
                url: 'admin/school-subject-units/',
                method: 'get',
            },
            Retrieve: {
                url: 'admin/school-subject-units/',
                method: 'get',
            },
            Create: {
                url: 'admin/school-subject-units/',
                method: 'post',
            },
            Patch: {
                url: 'admin/school-subject-units/',
                method: 'patch',
            },
            Delete: {
                url: 'admin/school-subject-units/',
                method: 'delete',
            },
            CreateLearningElement: {
                url: 'admin/school-subject-units/',
                method: 'post',
            },
            DeleteLearningElement: {
                url: 'admin/school-subject-units/',
                method: 'post',
            },
            PatchLearningElement: {
                url: 'admin/school-subject-units/',
                method: 'post',
            },
        },
        StudySubjectOrder: {
            List: {
                url: 'admin/study-subject-orders/',
                method: 'get',
            },
        },
        MajorCurriculum: {
            List: {
                url: 'admin/major-curriculums/',
                method: 'get',
            },
        },
        Upload: {
            List: {
                url: 'admin/uploads/',
                method: 'get',
            },
            Retrieve: {
                url: 'admin/uploads/',
                method: 'get',
            },
            Create: {
                url: 'admin/uploads/',
                method: 'post',
            },
        },
        IssuedStudySubject: {
            List: {
                url: 'admin/issued-study-subjects/',
                method: 'get',
            },
            Retrieve: {
                url: 'admin/issued-study-subjects/',
                method: 'get',
            },
            Create: {
                url: 'admin/issued-study-subjects/',
                method: 'post',
            },
            Delete: {
                url: 'admin/issued-study-subjects/',
                method: 'delete',
            },
        },
        StudySubject: {
            List: {
                url: 'admin/study-subjects/',
                method: 'get',
            },
            Retrieve: {
                url: 'admin/study-subjects/',
                method: 'get',
            },
            Patch: {
                url: 'admin/study-subjects/',
                method: 'patch',
            },
            Create: {
                url: 'admin/study-subjects/',
                method: 'post',
            },
            Delete: {
                url: 'admin/study-subjects/',
                method: 'delete',
            },
        },
        StudySubjectUnit: {
            Retrieve: {
                url: 'admin/study-subject-units/',
                method: 'get',
            },
        },
        Concept: {
            List: {
                url: 'admin/concepts/',
                method: 'get',
            },
            Retrieve: {
                url: 'admin/concepts/',
                method: 'get',
            },
            Patch: {
                url: 'admin/concepts/',
                method: 'patch',
            },
            Delete: {
                url: 'admin/concepts/',
                method: 'delete',
            },
        },
        Mission: {
            List: {
                url: 'admin/missions/',
                method: 'get',
            },
        },
        MissionReport: {
            List: {
                url: 'admin/mission-reports/',
                method: 'get',
            },
            Retrieve: {
                url: 'admin/mission-reports/',
                method: 'get',
            },
            Create: {
                url: 'admin/mission-reports/',
                method: 'post',
            },
            Patch: {
                url: 'admin/mission-reports/',
                method: 'patch',
            },
            Delete: {
                url: 'admin/mission-reports/',
                method: 'delete',
            },
            Book: {
                url: 'admin/book/',
                method: 'get',
            },
        },
        DongaReport: {
            List: {
                url: 'admin/donga-reports/',
                method: 'get',
            },
            Retrieve: {
                url: 'admin/donga-reports/',
                method: 'get',
            },
            Patch: {
                url: 'admin/donga-reports/',
                method: 'patch',
            },
            Delete: {
                url: 'admin/donga-reports/',
                method: 'delete',
            },
        },
        ResearchAssistant: {
            List: {
                url: 'admin/research-assistant-profiles/',
                method: 'get',
            },
            Expel: {
                url: 'admin/research-assistant-profiles/',
                method: 'post',
            },
        },
        Student: {
            List: {
                url: 'admin/student-profiles/',
                method: 'get',
            },
        },
        StudentProfileSchoolSubjectMap: {
            Create: {
                url: 'admin/student-profile-school-subject-maps/',
                method: 'post',
            },
        },
        PointLog: {
            List: {
                url: 'admin/point-logs/',
                method: 'get',
            },
        },
        PointsWithdrawal: {
            List: {
                url: 'admin/points-withdrawals/',
                method: 'get',
            },
            Settle: {
                url: 'admin/points-withdrawals/',
                method: 'post',
            },
            Excel: {
                url: 'admin/points-withdrawals/excel/',
                method: 'get',
            },
        },
        TestReport: {
            List: {
                url: 'admin/test-reports/',
                method: 'get',
            },
            Retrieve: {
                url: 'admin/test-reports/',
                method: 'get',
            },
            Patch: {
                url: 'admin/test-reports/',
                method: 'patch',
            },
        },
        Statistic: {
            StudySubjectDemandSupply: {
                url: 'admin/statistics/',
                method: 'get',
            },
        },
    },
    Staff: {
        StudySubject: {
            List: {
                url: 'staff/study-subjects/',
                method: 'get',
            },
            Retrieve: {
                url: 'staff/study-subjects/',
                method: 'get',
            },
            Patch: {
                url: 'staff/study-subjects/',
                method: 'patch',
            },
        },
        Concept: {
            List: {
                url: 'staff/concepts/',
                method: 'get',
            },
            Retrieve: {
                url: 'staff/concepts/',
                method: 'get',
            },
            Patch: {
                url: 'staff/concepts/',
                method: 'patch',
            },
        },
        MissionReport: {
            List: {
                url: 'staff/mission-reports/',
                method: 'get',
            },
            Retrieve: {
                url: 'staff/mission-reports/',
                method: 'get',
            },
            Patch: {
                url: 'staff/mission-reports/',
                method: 'patch',
            },
            Book: {
                url: 'staff/book/',
                method: 'get',
            },
        },
    },
};
