import React from 'react';

export const Pagination = ({
    pageBlock,
    page,
    setPage,
    before,
    next,
    lastPage,
    ...props
}) => {
    console.log(lastPage && lastPage);
    const handleBeforeClick = () => {
        if (before) {
            setPage(before);
        }
    };
    const handleNextClick = () => {
        if (next) {
            setPage(next);
        }
    };

    const handleLastClick = () => {
        if (lastPage) {
            setPage(lastPage);
        }
    };

    const handleFirstClick = () => {
        if (page) {
            setPage(1);
        }
    };

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
                {page && page != 1 && (
                    <li className="page-item">
                        <a onClick={handleFirstClick} className="page-link">
                            처음으로
                        </a>
                    </li>
                )}
                {before && (
                    <li className="page-item">
                        <a onClick={handleBeforeClick} className="page-link">
                            &#xE000;
                        </a>
                    </li>
                )}
                {pageBlock.map((pageNumber, key) => (
                    <li
                        className={`page-item ${
                            page === pageNumber ? 'active' : ''
                        }`}
                        key={key}
                    >
                        <a
                            className="page-link"
                            onClick={e => setPage(pageNumber)}
                        >
                            {pageNumber}
                        </a>
                    </li>
                ))}
                {next && (
                    <li onClick={handleNextClick} className="page-item">
                        <a className="page-link">&#xE001;</a>
                    </li>
                )}
                {lastPage && page < lastPage && (
                    <li onClick={handleLastClick} className="page-item">
                        <a className="page-link">맨 뒤로</a>
                    </li>
                )}
            </ul>
        </nav>
    );
};
