import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import { APIRoute } from 'Client';

import { getRemainingTime } from '../helpers';
import { RemoteDataTable, useRemoteDataTable } from 'Components/table/base';

const tableProperty = [
    {
        key: 'uid',
        name: '주문번호',
        template: (columnData, rowData) => {
            return <span>{columnData}</span>;
        },
    },
    {
        key: 'insertedTime',
        name: '주문시간',
        template: (columnData, rowData) => {
            return <span>{columnData}</span>;
        },
    },
    {
        key: 'quick',
        name: '남은시간',
        template: (columnData, rowData) => {
            if (rowData['issued']) {
                return <span className="text-main-color">발급 완료</span>;
            }
            return (
                <span
                    className={rowData['isEmergency'] ? 'quick-emergency' : ''}
                >
                    {columnData}
                </span>
            );
        },
    },
    {
        key: 'userName',
        name: '주문자명',
        template: (columnData, rowData) => {
            return <span className="table-name-data">{columnData}</span>;
        },
    },
    {
        key: 'subject__name',
        name: '희망과목',
        template: (columnData, rowData) => {
            return <span>{columnData}</span>;
        },
    },
    {
        key: 'isSchoolSubjectUnitSelected',
        name: '단원선택',
        template: (columnData, rowData) => {
            return <span>{columnData ? 'O' : '-'}</span>;
        },
    },
    {
        key: 'donga',
        name: '동아선택',
        template: (columnData, rowData) => {
            return <span>{columnData ? 'O' : '-'}</span>;
        },
    },
    {
        key: 'issued',
        name: '발급여부',
        template: (columnData, rowData) => {
            return <span>{columnData ? 'O' : '-'}</span>;
        },
    },
];

export const StudySubjectOrderTable = props => {
    const history = useHistory();
    const apiRoute = APIRoute.Admin.StudySubjectOrder.List;
    const formatData = data => {
        return data.map(datum => {
            const { hours, minutes } = getRemainingTime(datum);
            return {
                ...datum,
                isSchoolSubjectUnitSelected: datum.subjectUnit.length > 0,
                subject__name: datum.subject.name.replace('_', ' '),
                insertedTime: datum.insertedTime.substring(3),
                isEmergency: hours < 24 ? true : false,
                quick: `${hours}:${minutes}`,
            };
        });
    };

    const { form, reload, ...remoteDataTable } = useRemoteDataTable({
        apiRoute,
        formatData,
    });

    const clickHandler = (columnData, row) => {
        history.push({
            pathname: `/admin/main/study-subject-orders/${row.uid}/`,
        });
    };

    tableProperty.forEach(e => {
        e.clickHandler = clickHandler;
    });

    return (
        <>
            <Form onSubmit={form.handleSubmit}>
                <div className="admin-select-container">
                    <div className="input-group col-4 p-0">
                        <select name="search_field" ref={form.register}>
                            <option value="">선택</option>
                            <option value="user__name">유저 이름</option>
                            <option value="user__username">유저 아이디</option>
                            <option value="user__email">유저 이메일</option>
                            <option value="uid">주문 번호</option>
                        </select>
                        <input
                            name="search_value"
                            ref={form.register}
                            type="text"
                            className="form-control border-right-0 h-100 rounded-0"
                        />
                        <div className="input-group-append rounded-0">
                            <button
                                type="button"
                                className="admin-search-btn-container"
                                onClick={reload}
                            >
                                <svg
                                    width="1rem"
                                    height="1rem"
                                    viewBox="0 0 16 16"
                                    className="bi bi-search"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
            <div className="col-12 mt-4">
                <RemoteDataTable
                    tableProperty={tableProperty}
                    {...remoteDataTable}
                />
            </div>
        </>
    );
};
