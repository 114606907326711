import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { StudySubjectOrderDisplay } from 'Components/';
import { AppContext } from 'Contexts';
import { useAPI, APIRoute } from 'Client';
import { getSchoolCode } from 'Helpers';

export const AdminIssuedStudySubjectCreateView = props => {
    const {
        location: {
            state: {
                studySubject,
                studySubjectOrder,
                selectedSchoolSubject,
                selectedSchoolSubjectUnit,
                selectedAchievements,
            },
        },
    } = props;

    if (
        !studySubject ||
        !studySubjectOrder ||
        !selectedSchoolSubject ||
        !selectedSchoolSubjectUnit ||
        !selectedAchievements
    ) {
        return <></>;
    }

    const {
        getters: { getSchoolSubject, getSchoolSubjectUnit },
    } = useContext(AppContext);
    const history = useHistory();

    const schoolSubject = getSchoolSubject(selectedSchoolSubject);
    const schoolSubjectUnit = getSchoolSubjectUnit(selectedSchoolSubjectUnit);

    const issuedStudySubjectCreateAPI = useAPI(
        APIRoute.Admin.IssuedStudySubject.Create,
        {
            callbacks: {
                onSuccess: res => {
                    alert('주제 발급 성공');
                    history.push({
                        pathname: `/admin/main/issued-study-subjects/${res.id}/`,
                    });
                },
            },
        },
    );

    const handleCreateButtonClick = () => {
        if (!confirm('주제를 발급하시겠습니까?')) {
            return;
        }

        issuedStudySubjectCreateAPI.send({
            studySubject: studySubject.id,
            remoteOrderId: studySubjectOrder.uid,
            schoolCode: getSchoolCode(studySubjectOrder.school),
            schoolSubject: selectedSchoolSubject,
            schoolSubjectUnit: selectedSchoolSubjectUnit,
            achievements: selectedAchievements.map(
                e => `[${e.code}] ${e.description}`,
            ),
        });
    };

    return (
        <div className="mb-3">
            <div className="study-subject-container mt-3">
                <StudySubjectOrderDisplay
                    studySubjectOrder={studySubjectOrder}
                />
            </div>
            <div className="d-flex flex-column mt-3">
                <div className="mb-2">
                    <b>선택된 발표 주제</b>
                </div>
                <span>{studySubject.id}</span>
            </div>
            <div className="d-flex flex-column mt-3">
                <div className="mb-2">
                    <b>선택된 과목</b>
                </div>
                <span>{schoolSubject.name}</span>
            </div>
            <div className="d-flex flex-column mt-3">
                <div className="mb-2">
                    <b>선택된 단원</b>
                </div>
                <span>{schoolSubjectUnit.name}</span>
            </div>
            <div className="d-flex flex-column mt-3">
                <div className="mb-2">
                    <b>선택된 성취요소</b>
                </div>
                <div className="d-flex flex-column">
                    {selectedAchievements.map(e => (
                        <div className="mt-2">{`[${e.code}] ${e.description}`}</div>
                    ))}
                </div>
            </div>
            <div className="d-flex justify-content-center pt-3">
                <div className="d-flex justify-content-center align-items-center">
                    <div
                        className="w-100 p-3 ml-3 header-cal-btn d-flex align-items-center justify-content-center point-mobile"
                        onClick={() => handleCreateButtonClick()}
                    >
                        발급하기
                    </div>
                </div>
            </div>
        </div>
    );
};
