import React, { createContext, useEffect, useReducer } from 'react';

import { useAPI, APIRoute } from 'Client';

import { slices } from './AppContext.slices';
import {
    getActionEnum,
    getInitialState,
    getReducer,
    getStateFromLocalStorage,
    flushStateToLocalStorage,
} from './utils';

const CONTEXT_NAME = 'AppContext';

const Action = getActionEnum(slices);

const getDefaultState = () =>
    getStateFromLocalStorage(CONTEXT_NAME) || getInitialState(slices);

const reducer = getReducer(slices, getDefaultState());

const getDispatchers = (state, dispatch) => ({
    clear: () => {
        const initialState = getInitialState(slices);
        dispatch({ type: Action.User.SET, payload: initialState.user });
        dispatch({ type: Action.Admin.SET, payload: initialState.admin });
        dispatch({ type: Action.Staff.SET, payload: initialState.staff });
        dispatch({
            type: Action.ResearchAssistant.SET,
            payload: initialState.researchAssistant,
        });
    },

    user: {
        set: user => dispatch({ type: Action.User.SET, payload: user }),
    },
    staff: {
        set: user => dispatch({ type: Action.Staff.SET, payload: user }),
        setProfile: profile =>
            dispatch({
                type: Action.Staff.SET_PROFILE,
                payload: profile,
            }),
    },
    admin: {
        set: user => dispatch({ type: Action.Admin.SET, payload: user }),
        setProfile: profile =>
            dispatch({
                type: Action.Admin.SET_PROFILE,
                payload: profile,
            }),
        setSelectedSchoolSubject: selectedSchoolSubject =>
            dispatch({
                type: Action.Admin.SET_SELECTED_SCHOOL_SUBJECT,
                payload: selectedSchoolSubject,
            }),
        setSelectedSchoolSubjectUnit: selectedSchoolSubjectUnit =>
            dispatch({
                type: Action.Admin.SET_SELECTED_SCHOOL_SUBJECT_UNIT,
                payload: selectedSchoolSubjectUnit,
            }),
        setSelectedAchievements: selectedAchievements =>
            dispatch({
                type: Action.Admin.SET_SELECTED_ACHIEVEMENTS,
                payload: selectedAchievements,
            }),
    },
    researchAssistant: {
        setProfile: profile =>
            dispatch({
                type: Action.ResearchAssistant.SET_PROFILE,
                payload: profile,
            }),
        setLoading: loading =>
            dispatch({
                type: Action.ResearchAssistant.SET_LOADING,
                payload: loading,
            }),
        setCurrentMissionId: missionId =>
            dispatch({
                type: Action.ResearchAssistant.SET_CURRENT_MISSION_ID,
                payload: missionId,
            }),
        setSchoolSubjects: schoolSubjects =>
            dispatch({
                type: Action.ResearchAssistant.SET_SCHOOL_SUBJECTS,
                payload: schoolSubjects,
            }),
        setSchoolSubjectUnits: schoolSubjectUnits =>
            dispatch({
                type: Action.ResearchAssistant.SET_SCHOOL_SUBJECT_UNITS,
                payload: schoolSubjectUnits,
            }),
        setMajorCategories: majorCategories =>
            dispatch({
                type: Action.ResearchAssistant.SET_MAJOR_CATEGORIES,
                payload: majorCategories,
            }),
        setRewards: rewards =>
            dispatch({
                type: Action.ResearchAssistant.SET_REWARDS,
                payload: rewards,
            }),
    },
});

const getGetters = (state, dispatchers) => ({
    getMajorCategory: (value, key = 'id') =>
        Object.values(state.researchAssistant.majorCategories)
            .reduce((accumulated, current) => accumulated.concat(current), [])
            .find(_ => _[key] === value),
    getSchoolSubject: (value, key = 'id') =>
        Object.values(state.researchAssistant.schoolSubjects)
            .reduce((accumulated, current) => accumulated.concat(current), [])
            .find(_ => _[key] === value),
    getSchoolSubjectUnit: (value, key = 'id') =>
        state.researchAssistant.schoolSubjectUnits.find(_ => _[key] === value),
    getSchoolLearningElements: (value, key = 'id') => {
        let subjectUnitArr = state.researchAssistant.schoolSubjectUnits.filter(
            _ => _[key] === value,
        );
        return subjectUnitArr;
    },
});

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, getDefaultState());
    const dispatchers = getDispatchers(state, dispatch);
    const getters = getGetters(state, dispatchers);

    const researchAssistantInitialContextRetreiveAPI = useAPI(
        APIRoute.ResearchAssistant.InitialContext.Retrieve,
        {
            callbacks: {
                // set initial context on success
                onSuccess: result => {
                    dispatchers.researchAssistant.setSchoolSubjectUnits(
                        result.schoolSubjectUnits,
                    );
                    dispatchers.researchAssistant.setMajorCategories(
                        result.majorCategories,
                    );
                    dispatchers.researchAssistant.setSchoolSubjects(
                        result.schoolSubjects,
                    );
                    dispatchers.researchAssistant.setRewards;
                },
                finally: () => {
                    dispatchers.researchAssistant.setLoading(false);
                },
            },
        },
    );

    const authValidateAPI = useAPI(APIRoute.Common.Auth.Validate, {
        callbacks: {
            onSuccess: result => {
                const {
                    researchAssistantProfile,
                    adminProfile,
                    staffProfile,
                    ...user
                } = result;

                dispatchers.user.set(user);
                if (researchAssistantProfile) {
                    dispatchers.researchAssistant.setProfile(
                        researchAssistantProfile,
                    );
                } else if (adminProfile) {
                    dispatchers.admin.setProfile(adminProfile);
                } else if (staffProfile) {
                    dispatchers.staff.setProfile(staffProfile);
                }
            },
            onError: dispatchers.clear,
        },
    });

    // flush state to local storage everytime dispatch occurs
    useEffect(() => {
        flushStateToLocalStorage(CONTEXT_NAME, state);
    }, [state]);

    // fetch & set initial context when user logs in
    if (!state.staff?.profile) {
        localStorage.clear();
    }

    const {
        researchAssistant: { profile: researchAssistantProfile },
        admin: { profile: adminProfile },
        staff: { profile: staffProfile },
    } = state;
    useEffect(() => {
        if (
            !researchAssistantProfile.id &&
            !adminProfile.id &&
            !staffProfile.id
        ) {
            return;
        }
        researchAssistantInitialContextRetreiveAPI.send();
    }, [researchAssistantProfile, adminProfile, staffProfile]);

    // validate auth every 30 seconds
    useEffect(() => {
        const loop = () => {
            authValidateAPI.send();
            const timeout = setTimeout(loop, 60 * 1000);
            return () => clearTimeout(timeout);
        };
        return loop();
    }, []);

    return (
        <AppContext.Provider value={{ ...state, dispatchers, getters }}>
            {children}
        </AppContext.Provider>
    );
};
