import React, { useState } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { useAPI, APIRoute } from 'Client';

export const FindIdModal = ({
    isOpen = false,
    open = () => {},
    close = () => {},
}) => {
    const { register, watch, handleSubmit, errors: formErrors } = useForm();
    const [email, setEmail] = useState('');
    const findIdAPI = useAPI(APIRoute.Common.Auth.FindId, {
        callbacks: {
            onSuccess: data => {
                alert(`아이디는 '${data.username}'입니다.`);
                close();
            },
            onError: error => {
                setEmail('');
            },
        },
    });

    const onSubmit = data => {
        if (email) {
            findIdAPI.send({ email: email });
        } else {
            alert('가입하신 학교 이메일을 입력해주세요!');
        }
    };

    return (
        <Modal id="id-password-modal" className="modal-narrow" show={isOpen}>
            <Modal.Header className="d-block">
                <div className="row w-100">
                    <Button className="close" onClick={close}>
                        <span aria-hidden="true">&times;</span>
                    </Button>
                </div>
                <div className="row w-100 justify-content-center align-items-center">
                    <h4>아이디 찾기</h4>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="w-100">
                                <div className="row">
                                    <div className="col-12 pt-2 pb-2">
                                        <label>학교 이메일</label>
                                    </div>
                                    <div className="col-12">
                                        <input
                                            name="email"
                                            className="password-modal-input input w-100 pt-3 pb-3 rounded-0"
                                            onChange={e =>
                                                setEmail(e.target.value)
                                            }
                                            value={email}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12 pt-2 pb-2">
                                        <button className="btn-main-color w-100 pt-3 pb-3 rounded-0">
                                            아이디 확인
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    );
};
