import React, { useState, useEffect, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { useAPI, APIRoute } from 'Client';
import { StudySubjectTable } from 'Components/table';
import { AppContext } from 'Contexts';

import { MajorCurriculumDisplay, StudySubjectOrderDisplay } from 'Components';

export const AdminStudySubjectOrderDetailView = props => {
    const history = useHistory();
    const params = useParams();
    const { id: uid } = params;

    const {
        dispatchers,
        getters: { getMajorCategory, getSchoolSubject, getSchoolSubjectUnit },
    } = useContext(AppContext);

    const [order, setOrder] = useState(null);

    const [majorCategoryId, setMajorCategoryId] = useState(null);
    const [schoolSubjectId, setSchoolSubjectId] = useState(null);

    const [majorCurriculums, setMajorCurriculums] = useState(null);

    const orderListAPI = useAPI(APIRoute.Admin.StudySubjectOrder.List, {
        callbacks: {
            onSuccess: ({ results }) => {
                const [order] = results;
                if (!order) {
                    return;
                }

                const {
                    major: {
                        name: majorName,
                        university: { name: universityName },
                    },
                } = order;
                setOrder(order);
                curriculumListAPI.send({
                    major: majorName,
                    university: universityName,
                });
            },
        },
    });

    const curriculumListAPI = useAPI(APIRoute.Admin.MajorCurriculum.List, {
        callbacks: {
            onSuccess: res => {
                setMajorCurriculums(res);
            },
        },
    });

    const handleRowClick = (_, studySubject, formValues) => {
        history.push({
            pathname: `/admin/main/issued-study-subjects/inspect/${studySubject.id}/`,
            state: {
                studySubjectOrder: order,
            },
        });
    };

    useEffect(() => {
        orderListAPI.send({ uid, isForDetail: true });

        return () => {
            orderListAPI.cancel();
        };
    }, [uid]);

    useEffect(() => {
        if (!order) {
            return;
        }

        const {
            major: { category },
            subject,
        } = order;

        const [majorCategory] = category;

        setMajorCategoryId(majorCategory.id);
        setSchoolSubjectId(subject.id);
    }, [order]);

    return (
        <>
            <div
                className="admin-back-btn cursor-pointer mt-3 ml-3"
                onClick={() => history.goBack()}
            >
                &#xE000;&nbsp; 뒤로가기
            </div>
            {order && (
                <>
                    <StudySubjectOrderDisplay studySubjectOrder={order} />
                    {majorCurriculums && (
                        <MajorCurriculumDisplay
                            majorCurriculums={majorCurriculums}
                        />
                    )}
                    <div>
                        {majorCategoryId && schoolSubjectId && (
                            <StudySubjectTable
                                majorCategory={majorCategoryId}
                                schoolSubject={schoolSubjectId}
                                school={order && order.school}
                                clickHandler={handleRowClick}
                            />
                        )}
                    </div>
                </>
            )}
            <div className="form-row mt-3 mb-5">
                <div className="offset-4 col-3 justify-content-center align-items-center d-flex">
                    <button type="button" className="btn btn-danger btn-block">
                        삭제하기
                    </button>
                </div>
            </div>
        </>
    );
};
