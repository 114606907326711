import React, { useState, useContext } from 'react';

import { Modal, Button } from 'react-bootstrap';
import { transpose, pad2DArray } from 'Utils';
import { AppContext } from 'Contexts';
import { generateUuid } from 'Utils';

export const SearchRelatedSchoolSubjectUnitsModal = ({
    handleClick,
    isOpen = false,
    close,
    schoolSubject,
}) => {
    const {
        researchAssistant: { schoolSubjectUnits },
    } = useContext(AppContext);

    const handleSchoolSubjectUnitClick = schoolSubjectUnit => {
        handleClick(schoolSubjectUnit);
        close();
    };

    return (
        <Modal
            show={isOpen}
            onHide={close}
            id="select-related-subject-unit-modal"
            className="modal-select-relatives"
        >
            <Modal.Header>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={close}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row w-100">
                        {schoolSubjectUnits
                            .filter(
                                unit => unit.schoolSubject === schoolSubject,
                            )
                            .map((schoolSubjectUnit, index) => (
                                <div
                                    className="col-6 p-3 cursor-pointer"
                                    onClick={() =>
                                        handleSchoolSubjectUnitClick(
                                            schoolSubjectUnit,
                                        )
                                    }
                                    key={index}
                                >
                                    {schoolSubjectUnit.name.replace(/_/g, ' ')}
                                </div>
                            ))}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
