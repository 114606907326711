import React, { useEffect, useState } from 'react';

export const MajorCurriculumDisplay = ({ majorCurriculums, ...props }) => {
    const [curriculumIndex, setCurriculumIndex] = useState('');

    useEffect(() => {
        setCurriculumIndex(Object.entries(majorCurriculums)[0][0]);
    }, []);

    return (
        <div className="admin-case-order-curriculum-container ml-4">
            <div className="admin-case-order-curriculum-title">커리큘럼</div>
            <div className="admin-case-order-curriculum-grade">
                {Object.entries(majorCurriculums).map((c, key) => (
                    <div
                        key={key}
                        className={
                            c[0] === curriculumIndex
                                ? 'active'
                                : 'cursor-pointer'
                        }
                        onClick={() => setCurriculumIndex(c[0])}
                    >
                        {c[0]}
                    </div>
                ))}
            </div>
            <div className="admin-case-order-curriculum-contents">
                {Object.entries(majorCurriculums).map((c, key) => (
                    <div key={key}>
                        {c[0] === curriculumIndex && (
                            <div>{c[1].join(', ')}</div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
