export const getRemainingTime = result => {
    const now = new Date(Date.now()).getTime();

    let insertedTime =
        '20' +
        result.insertedTime.replace(/\//gi, '-').replace(' ', 'T') +
        ':00';
    let temp_date = new Date(Date.parse(insertedTime));
    insertedTime = new Date(Date.parse(insertedTime)).getTime();

    let index = 0;
    let count = 0;
    let remainHours = 0;
    let remainMinutes = 0;

    while (true) {
        if (temp_date.getTime() > now) {
            break;
        } else {
            if (temp_date.getDay() === 6 || temp_date.getDay() === 0) {
                if (index === 0) {
                    let hour = Number(result.insertedTime.split(' ')[1].split(':')[0]);
                    let minute = Number(result.insertedTime.split(' ')[1].split(':')[1])
                    if (minute === 0) {
                        remainHours = 24 - hour
                    }
                    else {
                        remainHours = 23 - hour;
                        remainMinutes = 60 - minute
                    }
                }
                else {
                    count++
                }
            }
        }
        temp_date.setDate(temp_date.getDate() + 1);
        index++
    }

    let timeDelta = 0;
    if (result.quick) {
        timeDelta =
            insertedTime +
            1000 * 60 * 60 * 24 -
            now +
            count * 1000 * 60 * 60 * 24;
    } else {
        timeDelta =
            insertedTime +
            1000 * 60 * 60 * 72 -
            now +
            count * 1000 * 60 * 60 * 24;
    }

    let hours = Math.ceil(timeDelta / (1000 * 60 * 60)) - 1 + remainHours;
    let minutes = Math.ceil((timeDelta % (1000 * 60 * 60)) / (1000 * 60)) + remainMinutes;

    if (minutes > 60) {
        hours += 1
        minutes -= 60
    }

    return {
        hours,
        minutes,
    };
};
