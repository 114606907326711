import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { getRemainingTime } from './helpers';

export const StudySubjectOrderDisplay = ({ studySubjectOrder, ...props }) => {
    const { hours, minutes } = getRemainingTime(studySubjectOrder);
    const history = useHistory();

    const handleStudySubjectIdClick = studySubject => {
        history.push({
            pathname: `/admin/main/study-subjects/${studySubject.id}/`,
            state: {
                entity: studySubject,
            },
        });
    };

    return (
        <div>
            <div className="admin-case-order-header">
                <table className="table table-bordered admin-case-order-table">
                    <tbody>
                        <tr>
                            <th className="admin-case-order-box">주문번호</th>
                            <td className="admin-case-order-box">
                                {studySubjectOrder.uid}
                            </td>
                            <th className="admin-case-order-box">남은시간</th>
                            <td className="admin-case-order-box">
                                {studySubjectOrder.issued ? (
                                    <>
                                        <span>발급완료</span>
                                    </>
                                ) : (
                                    `${hours}:${minutes}`
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th className="admin-case-order-box">주문자ID</th>
                            <td className="admin-case-order-box">
                                {studySubjectOrder.user.username}
                            </td>
                            <th className="admin-case-order-box">과학동아</th>
                            <td className="admin-case-order-box">
                                {studySubjectOrder.donga ? 'O' : '-'}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="table table-bordered admin-case-order-table">
                    <tbody>
                        <tr>
                            <th className="admin-case-order-box">이름</th>
                            <td className="admin-case-order-box">
                                {studySubjectOrder.user.name}
                            </td>
                            <th className="admin-case-order-box">학교</th>
                            <td className="admin-case-order-box">
                                {studySubjectOrder.school?.name}
                            </td>
                        </tr>
                        <tr>
                            <th className="admin-case-order-box">희망 대학</th>
                            <td className="admin-case-order-box">
                                {studySubjectOrder.major.university.name}
                            </td>
                            <th className="admin-case-order-box">희망 학과</th>
                            <td className="admin-case-order-box">
                                {studySubjectOrder.major.name.slice(0, 15)}
                                {studySubjectOrder.major.name.length > 15
                                    ? '...'
                                    : ''}
                            </td>
                        </tr>
                        <tr>
                            <th className="admin-case-order-box">희망 과목</th>
                            <td className="admin-case-order-box">
                                {studySubjectOrder.subject.name.replace(
                                    /_/g,
                                    ' ',
                                )}
                            </td>
                            <th className="admin-case-order-box">
                                발급 소계열
                            </th>
                            <td className="admin-case-order-box">
                                {studySubjectOrder.major.category.map(
                                    (item, i) => (
                                        <div key={i} className="mr-3">
                                            {item.subcategoryName}
                                        </div>
                                    ),
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th className="admin-case-order-box">발급 유형</th>
                            <td className="admin-case-order-box">
                                {studySubjectOrder.option}
                            </td>
                            <th className="admin-case-order-box">
                                요청 키워드
                            </th>
                            <td className="admin-case-order-box">
                                {studySubjectOrder.keyword
                                    ? studySubjectOrder.keyword
                                    : '-'}
                            </td>
                        </tr>
                        <tr>
                            <th className="admin-case-order-box">희망 단원</th>
                            <td className="admin-case-order-box">
                                {studySubjectOrder.subjectUnit.length === 0 ? (
                                    <div className="mr-3">단원선택 없음</div>
                                ) : (
                                    studySubjectOrder.subjectUnit.map(
                                        (item, key) => (
                                            <div key={key} className="mr-3">
                                                {item.name.replace(/_/g, ' ')}
                                            </div>
                                        ),
                                    )
                                )}
                            </td>
                            <th className="admin-case-order-box">
                                기입력 주제
                            </th>
                            <td className="admin-case-order-box">
                                {studySubjectOrder.info
                                    ? studySubjectOrder.info
                                    : '-'}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="ml-4 admin-case-order-before-container">
                <div className="admin-case-order-before-title">
                    기존 발급 발표 주제
                </div>
                <div className="admin-case-order-before-content">
                    {studySubjectOrder.issuedStudySubjects.map(
                        (issuedStudySubject, key) => (
                            <React.Fragment key={key}>
                                <a
                                    data-for={`study-subject-tooltip-${key}`}
                                    data-tip
                                    onClick={() =>
                                        handleStudySubjectIdClick(
                                            issuedStudySubject.studySubject,
                                        )
                                    }
                                    style={{ marginRight: 5 }}
                                >
                                    {issuedStudySubject.studySubject?.id}
                                </a>
                                <ReactTooltip
                                    className="tool-tip-container"
                                    id={`study-subject-tooltip-${key}`}
                                    place="bottom"
                                    type="info"
                                    effect="solid"
                                    getContent={() =>
                                        issuedStudySubject.studySubject?.task
                                    }
                                />
                            </React.Fragment>
                        ),
                    )}
                </div>
            </div>
            <br />
        </div>
    );
};
